<template>
  <div class="expandable-table__wrapper">
    <cp-h1>
      Resident Satisfaction
      <template v-if="devMode" #subtitle>
        <router-link
          :to="{
            name: 'widget_docs',
            params: {
              category: 'properties',
              widget: 'resident_satisfaction',
              id: $router.currentRoute.params.id,
            },
            query: $router.currentRoute.query,
          }"
        >
          <v-icon color="primary">mdi-code-greater-than</v-icon> docs
        </router-link>
      </template>

      <template #right>
        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <div class="card-row">
      <div class="card-row-fields">
        <div class="card-row-fields__container">
          <table class="card-row-fields__table">
            <tbody>
              <tr>
                <td class="text-caption">Viewing By:</td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn color="primary" class="mr-2" v-on="on" text small>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedScope.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedScopeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="s in scopes" :key="s.value">
                          {{ s.label }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td class="pr-3 text-caption">
                  Date Range:
                </td>
                <td>
                  <v-menu>
                    <template #activator="{on}">
                      <v-btn v-on="on" color="primary" small text>
                        <v-icon left>mdi-chevron-down</v-icon>
                        {{ selectedDateRange.label }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="selectedDateRangeIndex"
                        color="primary"
                      >
                        <v-list-item v-for="dr in dateRanges" :key="dr.value">
                          <v-list-item-content>{{
                            dr.label
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-row-cards">
        <stat-cards v-bind="{ statCards, table }" />
      </div>
    </div>

    <v-card class="mt-3">
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>

        <template #item.question_response_summary="{item}">
          <question-response-summary v-bind="{ item, table }" />
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData && !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { snakeToSentenceCase } from "@cp/utils/stringUtils";

import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";
import { coaxRoundPrct, score } from "@/lib/formats";

const scopeHeaders = {
  default: [
    { text: "", value: "name" },
    {
      text: "Score",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    {
      text: "Likely to renew",
      value: "likely_to_renew_percentage",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
    { text: "Sent", value: "surveys_sent", width: 90, align: "end" },
    { text: "Started", value: "surveys_started", width: 100, align: "end" },
    { text: "Completed", value: "surveys_completed", width: 125, align: "end" },
    {
      text: "Response Rate",
      value: "response_rate",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
  ],
  surveys: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "sb_index.agreement_importance",
      sortable: false,
      width: 80,
      format: score,
    },
    {
      text: "Score",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    {
      text: "Likely to renew",
      value: "likely_to_renew_percentage",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
    { text: "Sent", value: "surveys_sent", width: 90, align: "end" },
    { text: "Started", value: "surveys_started", width: 100, align: "end" },
    { text: "Completed", value: "surveys_completed", width: 125, align: "end" },
    {
      text: "Response Rate",
      value: "response_rate",
      width: 150,
      align: "end",
      format: coaxRoundPrct,
    },
  ],
  questions: [
    { text: "", value: "name" },
    {
      text: "SBX",
      value: "sb_index.agreement_importance",
      sortable: false,
      width: 80,
      format: score,
    },
    {
      text: "Score",
      value: "agreement_importance",
      width: 140,
      align: "center",
    },
    { text: "Type", value: "question_type_slug" },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return { scopeHeaders };
  },
  methods: { snakeToSentenceCase },
};
</script>
