import { mergeMixins, Table, HaystackSearch } from "@cp/store/mixins";
import { snakeToTitleCase } from "@cp/utils/stringUtils";

export const propertyReportMenuOrder = [
  "resident_satisfaction",
  "resident_reasons_leaving_report",
  "resident_comments",
  "employee_priorities",
  "employee_development",
  "employee_compliance",
];

export const regionReportMenuOrder = [
  "locations",
  "resident_satisfaction",
  "resident_reasons_leaving_report",
  "resident_comments",
  "employee_priorities",
  "employee_comments",
  "employee_development",
  "employee_compliance",
];

export const reportWidgets = Object.entries({
  locations: {
    serviceRequired: "intel",
    icon: "mdi-home-city-outline",
  },
  resident_satisfaction: {
    serviceRequired: "intouch",
    icon: "mdi-emoticon-happy-outline",
  },
  resident_reasons_leaving_report: {
    serviceRequired: "intouch",
    icon: "mdi-target",
    title: "Reasons Residents Are Leaving",
  },
  resident_comments: {
    serviceRequired: "intouch",
    icon: "mdi-account-voice",
  },
  employee_priorities: {
    serviceRequired: "ingage",
    title: "Strengths and Opportunities",
    icon: "mdi-bullseye-arrow",
    iconProps: { color: "success" },
  },
  employee_comments: {
    serviceRequired: "ingage",
    icon: "mdi-account-voice",
  },
  employee_development: {
    serviceRequired: "introduce",
    title: "Onboarding and Offboarding",
    icon: "mdi-bullseye",
    iconProps: { color: "error" },
  },
  employee_compliance: {
    serviceRequired: "introduce",
    title: "Task Compliance",
    icon: "mdi-checkbox-multiple-marked-outline",
  },
}).reduce((r, [key, value]) => {
  const { title = snakeToTitleCase(key), ...rest } = value;
  r[key] = { title, ...rest };
  return r;
}, {});

export const portfolioTable = new Table({
  module: "portfolio",
  baseUrl: `${process.env.VUE_APP_HAYSTACK_API_PATH}/en/v1/portfolio`,
  name: "items",
  jsonApiResponse: false,
  params: {
    structures: {
      scope: "regions",
      addon_options: "summary",
    },
  },
  initialMeta: {
    summary_object: {},
  },
  filters: [
    "q_text",
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: 1,
    },
    {
      label: "Areas",
      type: "sub_query",
      key: "areas",
      multiple: true,
    },
    {
      label: "Region",
      type: "sub_query",
      key: "regions",
      multiple: true,
    },
    {
      label: "Reporting Groups",
      type: "sub_query",
      key: "reporting_groups_by_locations",
      multiple: true,
    },
  ],
  filtersOptions: {
    protectedQueryKeys: ["scope"],
  },
  state: {
    scopes: [
      { label: "Regions", value: "regions" },
      { label: "Locations", value: "locations" },
      { label: "Reporting Groups", value: "reporting_groups" },
    ],
  },
  getters: {
    locationWidgets(state, getters, rootState, rootGetters) {
      const clientServices = rootGetters["haystackUser/clientServices"];
      return propertyReportMenuOrder.filter(id =>
        clientServices.includes(reportWidgets[id].serviceRequired)
      );
    },
    regionWidgets(state, getters, rootState, rootGetters) {
      const clientServices = rootGetters["haystackUser/clientServices"];
      return regionReportMenuOrder.filter(id =>
        clientServices.includes(reportWidgets[id].serviceRequired)
      );
    },
  },
});

export const myRegions = new HaystackSearch({
  module: "portfolio",
  urlKey: "regions",
  name: "myRegions",
  params: { page: { size: -1 } },
});

window.$myRegions = myRegions;

export const myRegionLocations = new HaystackSearch({
  module: "portfolio",
  urlKey: "locations",
  name: "myRegionLocations",
  params: { page: { size: -1 } },
});

export default mergeMixins(portfolioTable, myRegions, myRegionLocations);
