<template>
  <v-sheet class="PortfolioBreadcrumbs" v-if="parent.name" elevation="3">
    <template v-for="(item, i) in items">
      <span v-if="i" :key="`delimiter-${i}`" class="delimiter">/</span>
      <v-icon
        v-if="item.icon"
        v-bind="item.iconProps || {}"
        :key="`icon-${i}`"
        class="ml-2 mr-3"
      >
        {{ item.icon }}
      </v-icon>
      <router-link v-if="item.to" :key="`link-${i}`" :to="item.to">{{
        item.text
      }}</router-link>
      <span v-else :key="`text-${i}`">{{ item.text }}</span>
      <v-menu
        v-if="item.children && item.children.length"
        :key="`menu${i}`"
        offset-y
      >
        <template #activator="{on}">
          <v-btn v-on="on" color="primary" class="ml-2" small text tile fab>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <template v-for="(child, i) in item.children">
            <v-subheader v-if="!child.to" :key="`subhead-${i}`">{{
              child.text
            }}</v-subheader>
            <v-list-item v-else :key="`list-item-${i}`" :to="child.to">
              <v-list-item-icon v-if="child.icon">
                <v-icon v-bind="child.iconProps || {}">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ child.text }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-menu
        v-else-if="item.switcher"
        :key="`${item.switcher.routeName}-switcher${i}`"
        @input="item.switcher.input"
        offset-y
      >
        <template #activator="{on}">
          <v-btn v-on="on" color="primary" class="ml-2" small text tile fab>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="switcherList" dense>
          <v-subheader
            :key="`${item.switcher.routeName}-switcher-subhead-${i}`"
          >
            Select {{ item.switcher.noun }}
          </v-subheader>
          <v-list-item v-if="item.switcher.loading">
            <v-list-item-icon>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>Loading...</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(listItem, i) in item.switcher.items"
            :key="`${item.switcher.routeName}-switcher-list-item-${i}`"
            :to="{
              name: item.switcher.routeName,
              params: { id: listItem.value },
            }"
          >
            <v-list-item-content>
              {{ listItem.label }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { get } from "@cp/utils/objectUtils";

import {
  reportWidgets,
  myRegions,
  myRegionLocations,
} from "@/store/modules/portfolio";

export default {
  props: ["parent"],
  computed: {
    ...mapGetters("haystackUser", ["thisAppAccessLevel", "managedId"]),
    ...mapGetters("portfolio", ["regionWidgets", "locationWidgets"]),
    userCan() {
      const roleCan = {
        viewRegionDashboard: [
          "regional_manager",
          "client-admin",
          "reporting",
          "multi_regional_manager",
        ],
        switchRegions: ["client-admin", "reporting", "multi_regional_manager"],
        switchLocations: [
          "regional_manager",
          "client-admin",
          "reporting",
          "multi_property_manager",
          "multi_regional_manager",
        ],
      };
      return Object.keys(roleCan).reduce((r, key) => {
        r[key] = roleCan[key].includes(this.thisAppAccessLevel);
        return r;
      }, {});
    },
    rootPortfolioLink() {
      switch (this.thisAppAccessLevel) {
        case "property_manager":
          return {
            text: "Property",
            icon: "mdi-home-city",
            to: `/property/${this.managedId}`,
          };
        case "regional_manager":
          return {
            text: "Region",
            icon: "mdi-home-city",
            to: `/region/${this.managedId}`,
          };
        case "client-admin":
        case "reporting":
        case "multi_property_manager":
        case "multi_regional_manager":
          return {
            text: "Portfolio",
            icon: "mdi-home-city",
            to: { name: "portfolio" },
          };
      }
    },
    siblingReports() {
      const siblings =
        this.$route.name === "region"
          ? this.regionWidgets
          : this.locationWidgets;
      return siblings.map(key => {
        const widget = reportWidgets[key];
        return {
          icon: widget.icon,
          iconProps: widget.iconProps,
          text: widget.title,
          to: {
            name: this.$route.name,
            params: { id: this.$route.params.id, widget: key },
          },
        };
      });
    },
    items() {
      const items = [this.rootPortfolioLink];

      const region = this.parent.location_type
        ? this.parent.region
        : { name: this.parent.name, id: this.parent.id };
      if (region.id) {
        const regionItem = { text: region.name };

        if (this.userCan.viewRegionDashboard) {
          regionItem.to = `/region/${region.id}`;
        }

        if (this.userCan.switchRegions) {
          regionItem.switcher = {
            noun: "Region",
            routeName: "region",
            loading: get(this.$store.state, myRegions.p.s.loading),
            items: get(this.$store.state, myRegions.p.s.stateKey),
            input: open => open && this.$store.dispatch(myRegions.p.a.fetch),
          };
        }

        items.push(regionItem);
      }

      const location = this.parent.location_type
        ? { name: this.parent.name, id: this.$route.params.id }
        : {};
      if (location.id) {
        const locationItem = {
          text: location.name,
          to: `/property/${location.id}`,
        };

        if (this.userCan.switchLocations) {
          locationItem.switcher = {
            noun: "Location",
            routeName: "property",
            loading: get(this.$store.state, myRegionLocations.p.s.loading),
            items: get(this.$store.state, myRegionLocations.p.s.stateKey),
            input: open =>
              open &&
              this.$store.dispatch(myRegionLocations.p.a.fetch, {
                params: { filters: { region: region.id } },
              }),
          };
        }

        items.push(locationItem);
      }

      if (this.$route.params.widget) {
        const widget = reportWidgets[this.$route.params.widget];
        items.push({
          text: `Report: ${widget.title}`,
          children: [{ text: "Select Report" }, ...this.siblingReports],
        });
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.PortfolioBreadcrumbs {
  margin-top: 22px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  .delimiter {
    margin: 0 12px;
    font-size: 0.8em;
    color: newColor(gray-5);
  }

  a,
  span {
    font-weight: 300;
  }
}

.switcherList {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>
