<template>
  <div class="expandable-table__wrapper">
    <cp-h1 css-class="align-center" right-class="align-center flex-grow-1 ml-8">
      Resident Satisfaction Report

      <template #right>
        <v-menu>
          <template #activator="{on}">
            <v-btn color="primary" class="mr-2" v-on="on" small>
              <v-icon left>mdi-chevron-down</v-icon>
              View By:
              {{ selectedScope.label }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group v-model="selectedScopeIndex" color="primary">
              <v-list-item v-for="s in scopes" :key="s.value">
                {{ s.label }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-spacer />

        <cp-filters-menu :module="table" noun="Report" />
      </template>
    </cp-h1>

    <list-stats-cards v-bind="{ statCards, table }" />

    <v-card>
      <expandable-table
        v-bind="tableProps"
        :instance="instance"
        :scope="scope"
        :scopeChangeLoading="scopeChangeLoading"
        :scopeHeaders="scopeHeaders"
        @update:options="updateTableOptions"
        item-key="m_id"
        hide-default-footer
      >
        <template #header.name>
          {{ selectedScope.label }}
        </template>

        <template #item.likely="{item}">
          {{
            coaxRoundPrct(
              item.likely_to_renew_top_block / item.likely_to_renew_responses
            )
          }}
        </template>

        <template #item.response_rate="{item}">
          {{ coaxRoundPrct(item.surveys_responded / item.surveys_sent) }}
        </template>

        <template #item.question_response_summary="{item}">
          <question-response-summary v-bind="{ item, table }" />
        </template>
      </expandable-table>

      <v-pagination
        v-if="!noData || !scopeChangeLoading"
        class="mt-2 text-center"
        v-bind="paginationProps"
        @input="changePage"
      />
    </v-card>
  </div>
</template>

<script>
import { widgetMixin, tableMixin, multiScopeMixin } from "@/widgets/mixins";

import { icons, colors } from "@/lib/colorsAndIcons";

const scopeHeaders = {
  default: [
    { text: "", value: "name" },
    { text: "Score", value: "agreement_importance", width: 140 },
    { text: "Likely to renew", value: "likely", width: 150 },
    { text: "Sent", value: "surveys_sent", width: 90 },
    { text: "Started", value: "surveys_started", width: 100 },
    { text: "Completed", value: "surveys_completed", width: 125 },
    { text: "Response Rate", value: "response_rate", width: 150 },
  ],
  questions: [
    { text: "", value: "name" },
    { text: "Type", value: "question_type_slug" },
    { text: "Responses", value: "response_count" },
    {
      text: "Summary",
      value: "question_response_summary",
      sortable: false,
      align: "center",
    },
  ],
};

export default {
  mixins: [widgetMixin, tableMixin, multiScopeMixin],
  data() {
    return { scopeHeaders };
  },
  computed: {
    statCards() {
      const cards = [
        {
          title: "Scores",
          icon: "target",
          stats: [
            [
              "Score",
              "agreement_importance",
              { icon: "thumb-up", iconProps: { color: "success" } },
            ],
            ["Portfolio Average", "client_index.agreement_importance"],
            ["Swift Bunny index", "sb_index.agreement_importance"],
          ],
        },
      ];
      if (this.scope === "surveys") {
        cards.push(
          {
            title: "Satisfaction",
            icon: "emoticon-happy-outline",
            stats: [
              [
                "Likely to Renew",
                ({
                  likely_to_renew_responses: a,
                  likely_to_renew_top_block: b,
                }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "emoticon-happy",
                  iconProps: { color: "success" },
                },
              ],
              ["Answered likelyhood", "likely_to_renew_responses"],
              ["Likely to renew", "likely_to_renew_top_block"],
            ],
          },
          {
            title: "Survey Response",
            icon: "file-document-edit-outline",
            stats: [
              [
                "Response Rate",
                ({ surveys_responded: a, surveys_sent: b }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "percent-outline",
                  iconProps: { color: "success" },
                },
              ],
              ["Surveys Sent", "surveys_sent"],
              ["Responded", "surveys_responded"],
              ["Surveys Completed", "surveys_completed"],
            ],
          }
        );
      } else {
        cards.push(
          {
            title: "Portfolio",
            icon: "home-city",
            stats: [
              [
                "Areas",
                "area_count",
                {
                  showIfEmpty: false,
                  icon: icons.district,
                  iconProps: { color: colors.district },
                },
              ],
              [
                "Regions",
                "region_count",
                {
                  showIfEmpty: false,
                  icon: icons.region,
                  iconProps: { color: colors.region },
                },
              ],
              [
                "Locations",
                "location_count",
                { icon: icons.property, iconProps: { color: colors.property } },
              ],
              ["Residents", "resident_count"],
            ],
          },
          {
            title: "Satisfaction",
            icon: "emoticon-happy-outline",
            stats: [
              [
                "Likely to Renew",
                ({
                  likely_to_renew_responses: a,
                  likely_to_renew_top_block: b,
                }) => b / a,
                {
                  format: this.coaxRoundPrct,
                  icon: "emoticon-happy",
                  iconProps: { color: "success" },
                },
              ],
              ["Surveys Sent", "surveys_sent"],
              [
                "Response Rate",
                ({ surveys_responded: a, surveys_sent: b }) => b / a,
                { format: this.coaxRoundPrct },
              ],
            ],
          }
        );
      }
      return cards;
    },
  },
};
</script>
