<template>
  <div v-if="hasData">
    <cp-h1 hide-back-button disable-set-document-title>
      Resident Satisfaction
      <template #subtitle>
        Score: {{ Math.round(data.current_score) }}
        <cp-explain v-if="data.deferred_to_region">
          Showing region score due to confidentiality
        </cp-explain>
      </template>
    </cp-h1>

    <v-card icon="account-heart" root-slot>
      <template #title> </template>
      <div class="d-flex align-stretch">
        <summary-line-chart id="satisfaction-line" v-bind="chartProps" />
        <v-row no-gutters>
          <v-col
            class="Stat text-center"
            v-for="stat in stats"
            :key="stat.id"
            :id="stat.id"
            :class="{ clickableStat: stat.modalProps || stat.to }"
            @click="stat.to && $router.push(stat.to)"
          >
            <div class="value">
              {{ nonEmpty(stat.value) ? stat.value : "N/A"
              }}<span v-if="stat.append">{{ stat.append }}</span>
            </div>
            <div class="label">
              <cp-explain v-if="stat.explainText">
                <template #activator="{on}">
                  <span v-on="on">
                    <v-icon :size="16">mdi-information-outline</v-icon>
                    <span>
                      {{ stat.label }}
                    </span>
                  </span>
                </template>
                {{ stat.explainText }}
              </cp-explain>
              <span v-else>
                <v-icon v-if="stat.icon">mdi-{{ stat.icon }}</v-icon>
                {{ stat.label }}
              </span>
            </div>
            <widget-modal
              v-if="stat.modalProps"
              v-bind="stat.modalProps"
              :activator="`#${stat.id}`"
            />
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { get } from "@cp/utils/objectUtils";

import widgetMixin from "@/widgets/mixin";
import { mapStrToQLabel } from "@/lib/quarterOfYear";

export default {
  mixins: [widgetMixin],
  computed: {
    hasData() {
      return get(this.data, "timelines.timelines.result_timeline", []).some(
        x => x
      );
    },
    chartProps() {
      const {
        timelines,
        labels,
        yMin: minYValue,
        yMax: maxYValue,
      } = this.data.timelines;
      const ySpan = maxYValue - minYValue;
      const yPad = ySpan * 0.2; // pad by 40%
      const yMin = Math.max(Math.round(parseFloat(minYValue) - yPad), 0);
      const yMax = Math.min(Math.round(parseFloat(maxYValue) + yPad), 100);

      const mapCoords = (y, x) => ({ x, y: parseFloat(y) });

      const datasets = [
        {
          fill: true,
          borderColor: "rgba(230, 126, 34, 1)",
          backgroundColor: "rgba(230, 126, 34, .1)",
          pointBackgroundColor: "rgba(230, 126, 34, 1)",
          borderWidth: 2,
          lineTension: 0.3,
          order: 2,
          data: timelines.result_timeline.map(mapCoords),
          label: this.parent.name,
        },
      ];
      return {
        modalProps: {
          url: `properties/${this.$route.params.id}/resident_satisfaction_trends`,
        },
        chartData: {
          labels: labels.map(mapStrToQLabel),
          datasets,
          yMin,
          yMax,
        },
        options: {
          spanGaps: true,
        },
        lineChartProps: {
          yMin,
          yMax,
        },
      };
    },
    stats() {
      return [
        {
          id: "resident-unlikely",
          label: "Unlikely to Renew",
          icon: "emoticon-angry",
          value: this.data.unlikely_to_renew_count,
          to: {
            name: "residents",
            query: {
              survey_options: "Unlikely to Renew",
              // we cannot use $route.params, as it may refer to a location_id.
              // residents page requires a property_id, which is only on parent
              properties: this.parent.id,
            },
          },
        },
        {
          id: "resident-service-requests",
          label: "Service Requests",
          icon: "account-alert",
          value: this.data.service_alert_count,
          to: {
            name: "residents",
            query: {
              survey_options: "Has Alerts",
              // we cannot use $route.params, as it may refer to a location_id.
              // residents page requires a property_id, which is only on parent
              properties: this.parent.id,
            },
          },
        },
        {
          id: "resident-comments",
          label: "Comments",
          icon: "message-outline",
          value: this.data.comment_count,
          to: {
            name: "resident-comments",
            params: this.$router.currentRoute.params,
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.Stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.6);
  &.clickableStat {
    cursor: pointer;
    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}
.value {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-weight: lighter;
}
.label {
  white-space: nowrap;

  span {
    vertical-align: middle;
  }
}
</style>
